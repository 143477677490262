const env = process.env.NODE_ENV; // 'development' or 'production'

const _default = {};

const development = {
  ..._default,
  baseUrl: "http://127.0.0.1:8080",
};

const production = {
  ..._default,
  baseUrl: process.env.REACT_APP_API_PATH,
};

const config = {
  development,
  test: development,
  production,
};

export default config[env];
