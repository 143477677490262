import {
  BarChartOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { QuestionType } from "../types/IQuestion";

export default function Sidebar() {
  const location = useLocation();

  return (
    <Sider
      width={200}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <Link to="/">
        <img src="/nq_panel_logo.png" alt="logo" className="logo" />
      </Link>
      <Menu
        mode="inline"
        defaultSelectedKeys={["/"]}
        defaultOpenKeys={["/add-question"]}
        selectedKeys={["/" + location.pathname.split("/")[1]]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.SubMenu
          key="/add-question"
          icon={<PlusOutlined />}
          title="Soru Ekle"
        >
          <Menu.Item key={`/generate-question/${QuestionType.TextAndText}`}>
            <NavLink to={`/generate-question/${QuestionType.TextAndText}`}>
              Yazılı (ChatGPT)
            </NavLink>
          </Menu.Item>
          <Menu.Item key={`/add-question/${QuestionType.TextAndText}`}>
            <NavLink to={`/add-question/${QuestionType.TextAndText}`}>
              Yazılı
            </NavLink>
          </Menu.Item>
          <Menu.Item key={`/add-question/${QuestionType.Image}`}>
            <NavLink to={`/add-question/${QuestionType.Image}`}>
              Resimli
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="/questions" icon={<UnorderedListOutlined />}>
          <NavLink to="/questions/1">Sorular</NavLink>
        </Menu.Item>
        <Menu.Item key="/users" icon={<UserOutlined />}>
          <NavLink to="/users/1">Kullanıcılar</NavLink>
        </Menu.Item>
        <Menu.Item key="/answers" icon={<CheckCircleOutlined />}>
          <NavLink to="/answers/1">Cevaplar</NavLink>
        </Menu.Item>
        <Menu.Item key="/stats" icon={<BarChartOutlined />}>
          <NavLink to="/stats">İstatistikler</NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
