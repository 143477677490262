import axios from "axios";
import config from "../config";
import { IAnswer } from "../types/IAnswer";
import { IPagination } from "../types/IPagination";
import { IQuestion, IQuestionContent } from "../types/IQuestion";
import { IQuestionStats } from "../types/IQuestionStats";
import { ISystemStats } from "../types/ISystemStats";
import { IUser } from "../types/IUser";
import { IUserStats } from "../types/IUserStats";
import { IBaseResponse } from "./responses/IBaseResponse";

axios.defaults.baseURL = config.baseUrl;
axios.defaults.responseType = "json";
axios.defaults.headers.common["Authorization"] = "Basic Z294OmdveF8qPw==";

if (process.env.NODE_ENV === "development") {
  axios.interceptors.request.use(
    (config) => {
      console.log(config.method?.toUpperCase() + " : " + config.url);
      console.log(config.data || "-");
      return config;
    },
    (err) => {
      console.log("REQUEST ERROR");
      console.log(err);
    }
  );
  axios.interceptors.response.use((response) => {
    console.log(response?.data);
    return response;
  });
}

export default class Service {
  static upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post<IBaseResponse<{ filepath: string }>>(
      `/v1/admin/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  static getSystemStats() {
    return axios.get<IBaseResponse<ISystemStats>>(`/v1/admin/system-stats`);
  }

  static getUserStats(userId: string) {
    return axios.get<IBaseResponse<IUserStats>>(
      `/v1/admin/user-stats/${userId}`
    );
  }

  static getQuestionStats(questionId: string) {
    return axios.get<IBaseResponse<IQuestionStats>>(
      `/v1/admin/question-stats/${questionId}`
    );
  }

  static getAnswers(page: number) {
    return axios.get<IBaseResponse<IPagination<IAnswer>>>("/v1/admin/answers", {
      params: {
        page,
      },
    });
  }

  static getUserAnswers(page: number, userId: string) {
    return axios.get<IBaseResponse<IPagination<IAnswer>>>(
      `/v1/admin/user/${userId}/answers`,
      {
        params: {
          page,
        },
      }
    );
  }

  static getQuestionAnswers(page: number, questionId: string) {
    return axios.get<IBaseResponse<IPagination<IAnswer>>>(
      `/v1/admin/question/${questionId}/answers`,
      {
        params: {
          page,
        },
      }
    );
  }

  static getUsers(page: number) {
    return axios.get<IBaseResponse<IPagination<IUser>>>("/v1/admin/users", {
      params: {
        page,
      },
    });
  }

  static getQuestionById(questionId: string) {
    return axios.get<IBaseResponse<IQuestion>>(
      `/v1/admin/question/${questionId}`
    );
  }

  static getQuestions(page: number) {
    return axios.get<IBaseResponse<IPagination<IQuestion>>>(
      "/v1/admin/questions",
      {
        params: {
          page,
        },
      }
    );
  }

  static createQuestion(questionContent: IQuestionContent) {
    return axios.post<IBaseResponse>(
      `/v1/admin/create-question`,
      questionContent
    );
  }

  static updateQuestion(id: string, questionContent: IQuestionContent) {
    return axios.put<IBaseResponse>(
      `/v1/admin/question/${id}`,
      questionContent
    );
  }

  static generateQuestion(topic: string) {
    return axios.post<IBaseResponse<IQuestionContent>>(
      `/v1/admin/generate-question`,
      {
        topic,
      }
    );
  }
}
