import { Breadcrumb, Card, Col, Row, Statistic } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React from 'react';
import Service from '../network/Service';
import { ISystemStats } from '../types/ISystemStats';

export default function SystemStatsPage() {

    const [loading, setLoading] = React.useState(true)
    const [stats, setStats] = React.useState<ISystemStats>()

    React.useEffect(() => {
        getSystemStats();
    }, [])

    const getSystemStats = async () => {
        setLoading(true);
        try {
            let response = await Service.getSystemStats();
            setStats(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return null;
    }

    return (
        <Layout style={{ padding: '0 24px 24px', minHeight: '100vh' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
                <Breadcrumb.Item>İstatistikler</Breadcrumb.Item>
            </Breadcrumb>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Row gutter={8}>
                    <Col span={8}>
                        <Card>
                            <Statistic title="Kullanıcı" value={stats?.userCount} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic title="Soru" value={stats?.questionCount} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic title="Cevap" value={stats?.answerCount} />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={8}>
                    <Col span={12}>
                        <Card>
                            <Statistic title="Doğru" value={stats?.correctAnswerCount} />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <Statistic title="Yanlış" value={stats?.wrongAnswerCount} />
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
