import { Card, Col, Row, Statistic } from 'antd';
import React from 'react';
import Service from '../network/Service';
import { IQuestionStats } from '../types/IQuestionStats';

interface QuestionStatsProps {
    questionId: string
}

export default function QuestionStats(props: QuestionStatsProps) {

    const [loading, setLoading] = React.useState(true)
    const [stats, setStats] = React.useState<IQuestionStats>()

    React.useEffect(() => {
        getQuestionStats();
    }, [])

    const getQuestionStats = async () => {
        setLoading(true);
        try {
            let response = await Service.getQuestionStats(props.questionId);
            setStats(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card loading={loading} bodyStyle={{ padding: 8 }}>
            <Row gutter={8}>
                <Col md={8} sm={24} xs={24}>
                    <Card>
                        <Statistic title="Toplam Cevap" value={(stats?.correctAnswerCount ?? 0) + (stats?.wrongAnswerCount ?? 0)} />
                    </Card>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Card>
                        <Statistic title="Doğru" value={stats?.correctAnswerCount} />
                    </Card>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Card>
                        <Statistic title="Yanlış" value={stats?.wrongAnswerCount} />
                    </Card>
                </Col>
            </Row>
        </Card>
    );
}
