import { Breadcrumb, Table, Tag } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Service from '../network/Service';
import { IAnswer } from '../types/IAnswer';
import { IPagination } from '../types/IPagination';
import stc from 'string-to-color';

interface Params {
    page: string
}

export default function AnswersPage({ match, history }: RouteComponentProps<Params>) {

    const [loading, setLoading] = React.useState(true)
    const [pageData, setPageData] = React.useState<IPagination<IAnswer>>()

    const columns = React.useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'questionId',
                key: 'questionId',
                render: (questionId: string, answer: IAnswer) => {
                    return <Link to={`/question/${questionId}`}>{answer.question?.content?.text || '???'}</Link>
                }
            },
            {
                title: 'Kullanıcı',
                dataIndex: 'userId',
                key: 'userId',
                render: (userId: string) => {
                    return <Link to={`/user/${userId}/answers/1`}><Tag color={stc(userId)}>{userId.split('-')[0] || '???'}</Tag></Link>
                }
            },
            {
                title: 'Cevap',
                dataIndex: 'option',
                key: 'option',
                render: (option: number, answer: IAnswer) => {
                    return answer.question?.content?.options[option].answer
                }
            },
            {
                title: 'Doğru mu?',
                dataIndex: 'isCorrect',
                key: 'isCorrect',
                render: (isCorrect: 'Y' | 'N') => {
                    switch (isCorrect) {
                        case 'Y': return <Tag color="green">Evet</Tag>
                        case 'N': return <Tag color="red">Hayır</Tag>
                        default: return <Tag>Bilinmiyor</Tag>
                    }
                }
            },
            {
                title: 'Cevap Tarihi',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt: string) => {
                    return moment(createdAt).format('lll')
                }
            }
        ];
    }, [])

    React.useEffect(() => {
        getAnswers();
    }, [match.params.page])

    const getAnswers = async () => {
        setLoading(true);
        try {
            let response = await Service.getAnswers(+match.params.page);
            setPageData(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    const onPageChange = React.useCallback((page) => {
        history.push(`/answers/${page}`)
    }, [history])

    return (
        <Layout style={{ padding: '0 24px 24px', minHeight: '100vh' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
                <Breadcrumb.Item>Cevaplar</Breadcrumb.Item>
            </Breadcrumb>
            <Content>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={pageData?.rows}
                    rowKey="id"
                    pagination={{
                        defaultCurrent: +match.params.page,
                        pageSize: pageData?.size,
                        total: pageData?.totalRows,
                        onChange: onPageChange
                    }}
                />
            </Content>
        </Layout>
    );
}
