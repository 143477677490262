import { Button, Col, Divider, Form, Input, message, Radio, Row } from "antd";
import React from "react";
import Service from "../network/Service";
import { IOption, IQuestionContent, QuestionType } from "../types/IQuestion";

export default function GPTQuestionInsert() {
  const [questionForm] = Form.useForm();
  const [gptForm] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const infoLink = questionForm.getFieldValue("infoLink");

  const saveQuestion = async (question: IQuestionContent) => {
    try {
      await Service.createQuestion(question);
      questionForm.resetFields();
      message.success("Soru kaydedildi.");
    } catch (error) {
      message.error("Soru kaydedilemedi!");
    }
  };

  const onGenerate = async (values: any) => {
    if (values.topic) {
      try {
        setLoading(true);
        questionForm.resetFields();
        const response = await Service.generateQuestion(values.topic);
        questionForm.setFieldsValue({
          text: response.data.data.text,
          infoLink: response.data.data.infoLink,
          options: response.data.data.options,
          correctOptionIndex: response.data.data.options.findIndex(
            (o) => o.isCorrect
          ),
        });
      } catch (error) {
        message.error("Soru üretilemedi!");
      } finally {
        setLoading(false);
      }
    }
  };

  const onSave = (values: any) => {
    let options = values.options.map((option: IOption, index: number) => {
      return {
        ...option,
        isCorrect: index === +values.correctOptionIndex,
      };
    });

    let question: IQuestionContent = {
      type: QuestionType.TextAndText,
      text: values.text,
      media: "",
      options: options,
      infoLink: values.infoLink,
    };

    saveQuestion(question);
  };

  return (
    <>
      <Form
        form={gptForm}
        layout="vertical"
        onFinish={onGenerate}
        autoComplete="off"
      >
        <Form.Item label="Hangi konuda soru üretilecek?">
          <Row gutter={8}>
            <Col flex="auto">
              <Form.Item name="topic" rules={[{ required: true }]}>
                <Input placeholder="Konu" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Üret
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <Divider />

      <Form
        form={questionForm}
        onFinish={onSave}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="text"
          label="Soru"
          rules={[{ required: true, message: "Soru boş bırakılamaz!" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <br />
        <Form.Item
          name="correctOptionIndex"
          rules={[{ required: true, message: "Doğru cevabı seçiniz!" }]}
        >
          <Radio.Group style={{ width: "100%" }}>
            <Form.List name="options" initialValue={["", "", ""]}>
              {(fields, { add, remove }) => {
                console.log(fields);
                return (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...field}
                        key={field.key}
                        style={{ width: "100%" }}
                        label={
                          <Radio value={field.name}>Cevap {index + 1}</Radio>
                        }
                        name={[field.name, "answer"]}
                        fieldKey={[field.fieldKey as any, "answer"]}
                        rules={[
                          {
                            required: true,
                            message: `Cevap ${index + 1} boş bırakılamaz!`,
                          },
                        ]}
                      >
                        <Input placeholder="Cevap" />
                      </Form.Item>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Radio.Group>
        </Form.Item>
        <br />
        <Form.Item label="Bilgilendirme adresi">
          <Row gutter={8}>
            <Col flex="auto">
              <Form.Item
                style={{ width: "100%" }}
                name="infoLink"
                fieldKey="infoLink"
                rules={[
                  {
                    type: "url",
                    message: "Bilgilendime adresi url olmalı!",
                  },
                  {
                    required: true,
                    message: `Bilgilendirme linki boş bırakılamaz!`,
                  },
                ]}
              >
                <Input placeholder="URL" />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" href={infoLink} target="_blank">
                Adrese git
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Soruyu Kaydet
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
