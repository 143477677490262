import { Breadcrumb, Table } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Service from '../network/Service';
import { IPagination } from '../types/IPagination';
import { IUser } from '../types/IUser';

interface Params {
    page: string
}

export default function UsersPage({ match, history }: RouteComponentProps<Params>) {

    const [loading, setLoading] = React.useState(true)
    const [pageData, setPageData] = React.useState<IPagination<IUser>>()

    const columns = React.useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                render: (id: string) => {
                    return <Link to={`/user/${id}/answers/1`}>{id}</Link>
                }
            },
            {
                title: 'Platform',
                dataIndex: 'platform',
                key: 'platform',
                render: (platfrom: 'android' | 'ios') => {
                    return <strong>{platfrom.toUpperCase()}</strong>
                }
            },
            {
                title: 'Marka',
                dataIndex: 'brand',
                key: 'brand'
            },
            {
                title: 'Model',
                dataIndex: 'model',
                key: 'model'
            },
            {
                title: 'OS Version',
                dataIndex: 'osVersion',
                key: 'osVersion'
            },
            {
                title: 'Kayıt Tarihi',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt: string) => {
                    return moment(createdAt).format('lll')
                }
            }
        ];
    }, [])

    React.useEffect(() => {
        getUsers();
    }, [match.params.page])

    const getUsers = async () => {
        setLoading(true);
        try {
            let response = await Service.getUsers(+match.params.page);
            setPageData(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    const onPageChange = React.useCallback((page) => {
        history.push(`/users/${page}`)
    }, [])

    return (
        <Layout style={{ padding: '0 24px 24px', minHeight: '100vh' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
                <Breadcrumb.Item>Kullanıcılar</Breadcrumb.Item>
            </Breadcrumb>
            <Content>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={pageData?.rows}
                    rowKey="id"
                    pagination={{
                        defaultCurrent: +match.params.page,
                        pageSize: pageData?.size,
                        total: pageData?.totalRows,
                        onChange: onPageChange
                    }}
                />
            </Content>
        </Layout>
    );
}
