import { Breadcrumb, Button, Card } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ImageQuestionUpsert from '../components/ImageQuestionUpsert';
import QuestionStats from '../components/QuestionStats';
import TextAndTextQuestionUpsert from '../components/TextAndTextQuestionUpsert';
import Service from '../network/Service';
import { IQuestion, QuestionType } from '../types/IQuestion';

interface Params {
    questionId: string
}

export default function QuestionDetailPage({ match }: RouteComponentProps<Params>) {

    const [loading, setLoading] = React.useState(true)
    const [question, setQuestion] = React.useState<IQuestion>()

    React.useEffect(() => {
        getQuestion();
    }, [])

    const getQuestion = async () => {
        setLoading(true);
        try {
            let response = await Service.getQuestionById(match.params.questionId);
            setQuestion(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    const questionView = React.useMemo(() => {
        switch (question?.content?.type) {
            case QuestionType.TextAndText:
                return <TextAndTextQuestionUpsert key={question?.id} question={question} />
            case QuestionType.Image:
                return <ImageQuestionUpsert key={question?.id} question={question} />
            default: return null;
        }
    }, [question])

    if (loading) {
        return null;
    }

    return (
        <Layout style={{ padding: '0 24px 24px', minHeight: '100vh' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
                <Breadcrumb.Item>Soru</Breadcrumb.Item>
                <Breadcrumb.Item>{match.params.questionId}</Breadcrumb.Item>
            </Breadcrumb>
            <Content>
                <Card>
                    <Link to={`${match.url}/answers/1`}>
                        <Button type="primary">Verilen Cevaplar</Button>
                    </Link>
                </Card>
                <QuestionStats questionId={match.params.questionId} />
                <Card>
                    {questionView}
                </Card>
            </Content>
        </Layout>
    );
}
