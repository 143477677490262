import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Sidebar from "./components/Sidebar";
import AddQuestionPage from "./pages/AddQuestionPage";
import AnswersPage from "./pages/AnswersPage";
import QuestionAnswersPage from "./pages/QuestionAnswersPage";
import QuestionDetailPage from "./pages/QuestionDetailPage";
import QuestionsPage from "./pages/QuestionsPage";
import SystemStatsPage from "./pages/SystemStatsPage";
import UserAnswersPage from "./pages/UserAnswersPage";
import UsersPage from "./pages/UsersPage";
import GenerateQuestionPage from "./pages/GenerateQuestionPage";

function App() {
  return (
    <Layout>
      <Sidebar />
      <Layout style={{ marginLeft: 200 }}>
        <Content>
          <Switch>
            <Redirect exact path="/" to="/stats" />
            <Route path="/add-question" component={AddQuestionPage} />
            <Route path="/generate-question" component={GenerateQuestionPage} />
            <Route exact path="/questions/:page" component={QuestionsPage} />
            <Route
              exact
              path="/question/:questionId"
              component={QuestionDetailPage}
            />
            <Route
              exact
              path="/question/:questionId/answers/:page"
              component={QuestionAnswersPage}
            />
            <Route exact path="/users/:page" component={UsersPage} />
            <Route
              exact
              path="/user/:userId/answers/:page"
              component={UserAnswersPage}
            />
            <Route exact path="/answers/:page" component={AnswersPage} />
            <Route exact path="/stats" component={SystemStatsPage} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
