import { Button, Form, Image, Input, message, Radio, Upload } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React from "react";
import Service from "../network/Service";
import {
  IOption,
  IQuestion,
  IQuestionContent,
  QuestionType,
} from "../types/IQuestion";

interface Props {
  question?: IQuestion;
}

export default function ImageQuestionUpsert(props: Props) {
  const [form] = Form.useForm();

  const saveQuestion = async (question: IQuestionContent) => {
    try {
      if (props.question) {
        await Service.updateQuestion(props.question.id, question);
        message.success("Soru güncellendi.");
      } else {
        await Service.createQuestion(question);
        form.resetFields();
        message.success("Soru kaydedildi.");
      }
    } catch (error) {
      message.error("Soru kaydedilemedi!");
    }
  };

  const onFinish = (values: any) => {
    let options = values.options.map((option: IOption, index: number) => {
      return {
        ...option,
        isCorrect: index === +values.correctOptionIndex,
      };
    });

    let question: IQuestionContent = {
      type: QuestionType.Image,
      text: values.text,
      media: values.media,
      options: options,
      infoLink: values.infoLink,
    };

    saveQuestion(question);
  };

  const upload = React.useCallback(async (options: UploadRequestOption) => {
    try {
      let response = await Service.upload(options.file as File);
      form.setFieldsValue({
        media: response.data.data.filepath,
      });
    } catch (error: any) {
      message.error("Resim yüklenirken bir hata oluştu!");
    }
  }, []);

  return (
    <Form
      form={form}
      initialValues={{
        text: props.question?.content?.text,
        media: props.question?.content?.media,
        infoLink: props.question?.content?.infoLink,
        options: props.question?.content?.options,
        correctOptionIndex: props.question?.content?.options.findIndex(
          (o) => o.isCorrect
        ),
      }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Upload.Dragger
        name="file"
        accept=".png,.jpg,.jpg"
        multiple={false}
        showUploadList={false}
        customRequest={upload}
      >
        <Form.Item
          name="media"
          valuePropName="src"
          rules={[
            { type: "url", message: "Resim adresi url olmalı!" },
            { required: true, message: `Resim boş bırakılamaz!` },
          ]}
        >
          <Image width="40vw" preview={false} />
        </Form.Item>
        <p className="ant-upload-text">
          Yüklemek için tıklayın veya bu alana resmi sürükleyin
        </p>
        <p className="ant-upload-hint">1 adet dosya seçilebilir</p>
      </Upload.Dragger>

      <br />
      <Form.Item
        name="text"
        label="Soru"
        rules={[{ required: true, message: "Soru boş bırakılamaz!" }]}
      >
        <Input.TextArea />
      </Form.Item>
      <br />
      <Form.Item
        name="correctOptionIndex"
        rules={[{ required: true, message: "Doğru cevabı seçiniz!" }]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Form.List name="options" initialValue={["", "", ""]}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...field}
                      key={field.key}
                      style={{ width: "100%" }}
                      label={
                        <Radio value={field.name}>Cevap {index + 1}</Radio>
                      }
                      name={[field.name, "answer"]}
                      fieldKey={[field.fieldKey as any, "answer"]}
                      rules={[
                        {
                          required: true,
                          message: `Cevap ${index + 1} boş bırakılamaz!`,
                        },
                      ]}
                    >
                      <Input placeholder="Cevap" />
                    </Form.Item>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Radio.Group>
      </Form.Item>
      <br />
      <Form.Item
        style={{ width: "100%" }}
        label="Bilgilendirme adresi"
        name="infoLink"
        fieldKey="infoLink"
        rules={[
          {
            type: "url",
            message: "Bilgilendime adresi url olmalı!",
          },
          { required: true, message: `Bilgilendirme linki boş bırakılamaz!` },
        ]}
      >
        <Input placeholder="URL" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
