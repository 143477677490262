import { Breadcrumb, Card, Table, Tag, Typography } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import QuestionStats from "../components/QuestionStats";
import Service from "../network/Service";
import { IAnswer } from "../types/IAnswer";
import { IPagination } from "../types/IPagination";

interface Params {
  questionId: string;
  page: string;
}

export default function QuestionAnswersPage({
  match,
  history,
}: RouteComponentProps<Params>) {
  const [loading, setLoading] = React.useState(true);
  const [pageData, setPageData] = React.useState<IPagination<IAnswer>>();

  const columns = React.useMemo(() => {
    return [
      {
        title: "Kullanıcı ID",
        dataIndex: "userId",
        key: "userId",
        render: (userId: string, answer: IAnswer) => {
          return (
            <Link to={`/user/${userId}/answers/1`}>{userId || "???"}</Link>
          );
        },
      },
      {
        title: "Cevap",
        dataIndex: "option",
        key: "option",
        render: (option: number, answer: IAnswer) => {
          return answer.question?.content?.options[option].answer;
        },
      },
      {
        title: "Doğru mu?",
        dataIndex: "isCorrect",
        key: "isCorrect",
        render: (isCorrect: "Y" | "N") => {
          switch (isCorrect) {
            case "Y":
              return <Tag color="green">Evet</Tag>;
            case "N":
              return <Tag color="red">Hayır</Tag>;
            default:
              return <Tag>Bilinmiyor</Tag>;
          }
        },
      },
      {
        title: "Cevap Tarihi",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => {
          return moment(createdAt).format("lll");
        },
      },
    ];
  }, []);

  React.useEffect(() => {
    getQuestionAnswers();
  }, [match.params.page]);

  const getQuestionAnswers = async () => {
    setLoading(true);
    try {
      let response = await Service.getQuestionAnswers(
        +match.params.page,
        match.params.questionId
      );
      setPageData(response.data.data);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = React.useCallback((page) => {
    history.push(`/question/${match.params.questionId}/answers/${page}`);
  }, []);

  return (
    <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
        <Breadcrumb.Item>Soru</Breadcrumb.Item>
        <Link to={`/question/${match.params.questionId}`}>
          <Breadcrumb.Item>{match.params.questionId}</Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item>Cevapları</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <QuestionStats questionId={match.params.questionId} />
        <Card loading={loading}>
          <Typography.Text>
            {pageData?.rows?.[0].question?.content?.text ||
              "Henüz bu soruya cevap veren yok!"}
          </Typography.Text>
        </Card>
        <Table
          loading={loading}
          columns={columns}
          dataSource={pageData?.rows}
          rowKey="id"
          pagination={{
            defaultCurrent: +match.params.page,
            pageSize: pageData?.size,
            total: pageData?.totalRows,
            onChange: onPageChange,
          }}
        />
      </Content>
    </Layout>
  );
}
