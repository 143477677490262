import { Breadcrumb } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import ImageQuestionUpsert from '../components/ImageQuestionUpsert';
import TextAndTextQuestionUpsert from '../components/TextAndTextQuestionUpsert';
import { QuestionType } from '../types/IQuestion';

interface Params {
    type: string
}

export default function AddQuestionPage({ match, history }: RouteComponentProps<Params>) {

    console.log(match.params.type)
    console.log(match)

    return (
        <Layout style={{ padding: '0 24px 24px', minHeight: '100vh' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
                <Breadcrumb.Item>Soru Ekle</Breadcrumb.Item>
            </Breadcrumb>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Switch>
                    <Route exact path={`${match.url}/${QuestionType.TextAndText}`}>
                        <TextAndTextQuestionUpsert />
                    </Route>
                    <Route exact path={`${match.url}/${QuestionType.Image}`}>
                        <ImageQuestionUpsert />
                    </Route>
                </Switch>
            </Content>
        </Layout>
    );
}
