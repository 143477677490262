export enum QuestionType {
    TextAndText = "text_and_text",
    TextAndImage = "text_and_image",
    Image = "image",
    GIF = "gif",
    Audio = "audio",
    Video = "video",
}

export interface IOption {
    isCorrect?: boolean,
    answer: string
}

export interface IQuestionContent {
    type: QuestionType,
    text: string,
    media?: string,
    options: IOption[],
    infoLink: string
}

export interface IQuestion {
    id: string,
    createdAt?: string,
    updatedAt?: string,
    content?: IQuestionContent
}