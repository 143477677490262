import { Card, Col, Row, Statistic } from 'antd';
import React from 'react';
import Service from '../network/Service';
import { IUserStats } from '../types/IUserStats';

interface UserStatsProps {
    userId: string
}

export default function UserStats(props: UserStatsProps) {

    const [loading, setLoading] = React.useState(true)
    const [stats, setStats] = React.useState<IUserStats>()

    React.useEffect(() => {
        getUserStats();
    }, [])

    const getUserStats = async () => {
        setLoading(true);
        try {
            let response = await Service.getUserStats(props.userId);
            setStats(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card loading={loading} bodyStyle={{ padding: 8 }}>
            <Row gutter={8}>
                <Col md={8} sm={24} xs={24}>
                    <Card>
                        <Statistic title="Toplam Cevap" value={(stats?.correctAnswerCount ?? 0) + (stats?.wrongAnswerCount ?? 0)} />
                    </Card>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Card>
                        <Statistic title="Doğru" value={stats?.correctAnswerCount} />
                    </Card>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Card>
                        <Statistic title="Yanlış" value={stats?.wrongAnswerCount} />
                    </Card>
                </Col>
            </Row>
        </Card>
    );
}
