import { Breadcrumb, Table } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Service from '../network/Service';
import { IPagination } from '../types/IPagination';
import { IQuestion } from '../types/IQuestion';

interface Params {
    page: string
}

export default function QuestionsPage({ match, history }: RouteComponentProps<Params>) {

    const [loading, setLoading] = React.useState(true)
    const [pageData, setPageData] = React.useState<IPagination<IQuestion>>()

    const columns = React.useMemo(() => {
        return [
            {
                title: 'Sorular',
                dataIndex: 'id',
                key: 'id',
                render: (id: string, question: IQuestion) => {
                    return <Link to={`/question/${id}`}>{question.content?.text || '???'}</Link>
                }
            },
            {
                title: 'Oluşturma Tarihi',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt: string) => {
                    return moment(createdAt).format('lll')
                }
            }
        ];
    }, [])

    React.useEffect(() => {
        getQuestions();
    }, [match.params.page])

    const getQuestions = async () => {
        setLoading(true);
        try {
            let response = await Service.getQuestions(+match.params.page);
            setPageData(response.data.data)
        } finally {
            setLoading(false);
        }
    }

    const onPageChange = React.useCallback((page) => {
        history.push(`/questions/${page}`)
    }, [])

    return (
        <Layout style={{ padding: '0 24px 24px', minHeight: '100vh' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>NextQuestion</Breadcrumb.Item>
                <Breadcrumb.Item>Sorular</Breadcrumb.Item>
            </Breadcrumb>
            <Content>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={pageData?.rows}
                    rowKey="id"
                    pagination={{
                        defaultCurrent: +match.params.page,
                        pageSize: pageData?.size,
                        total: pageData?.totalRows,
                        onChange: onPageChange
                    }}
                />
            </Content>
        </Layout>
    );
}
